import React from 'react';
import './css/Footer.css'; // Create and style the footer here

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>&copy; {new Date().getFullYear()} Bergen Telugu Association. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
