import React from 'react';
import './css/Header.css'; // You can style this as per your need

const Header: React.FC = () => {
    return (
        <header className="header">
            <div className="logo-container">
                <img src={`${process.env.PUBLIC_URL}/bta_logo.jpg`} alt="Bergen Telugu Association Logo"
                     className="logo"/>
            </div>
            <div className="header-content">
                <h1 className="header-title">Bergen Telugu Association (BTA)</h1>
                <p className="header-subtitle">Connecting the Telugu community in Bergen, Norway</p>
                <p className="header-org-number">Non-Profit Organization (Org.No.920712452)</p>
            </div>
        </header>
    );
};

export default Header;
