// src/components/SocialMediaLinks.tsx

import React from 'react';
import {FaFacebook, FaInstagram, FaTwitter, FaYoutube} from 'react-icons/fa';

const SocialMediaLinks: React.FC = () => {
    return (
        <div style={styles.container}>
            <a
                href="https://www.facebook.com/profile.php?id=100083866651047"
                target="_blank"
                rel="noopener noreferrer"
                className="icon facebook"
            >
                <FaFacebook/>
            </a>
            <a
                href="https://x.com/BergenTelugu"
                target="_blank"
                rel="noopener noreferrer"
                className="icon twitter"
            >
                <FaTwitter/>
            </a>
            <a
                href="https://www.instagram.com/bergenteluguassociation/"
                target="_blank"
                rel="noopener noreferrer"
                className="icon instagram"
            >
                <FaInstagram/>
            </a>
            <a
                href="https://www.youtube.com/@BergenTeluguAssociation"
                target="_blank"
                rel="noopener noreferrer"
                className="icon youtube"
            >
                <FaYoutube/>
            </a>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
    }
};

export default SocialMediaLinks;
