import React from 'react';
import {Helmet} from 'react-helmet';

import './css/About.css'; // Ensure this has styles for the About Us page

const About: React.FC = () => {
    return (
        <div className="about-us">
            <Helmet>
                <title>About Us - Bergen Telugu Association</title>
                <meta name="description"
                      content="Learn more about the Bergen Telugu Association, our mission, and our objectives."/>
            </Helmet>
            <h1>About Bergen Telugu Association (BTA)</h1>

            {/* Mission Section */}
            <section className="mission-section">
                <h2>Our Mission</h2>
                <p>
                    The mission of Bergen Telugu Association (BTA) is to unite and support the Telugu-speaking community
                    in Bergen, Norway. We aim to promote cultural heritage, celebrate traditions, and create a sense of
                    belonging for the Telugu diaspora.
                </p>
            </section>

            {/* Objectives Section */}
            <section className="objectives-section">
                <h2>Our Objectives</h2>
                <ul>
                    <li>To organize cultural and social events that celebrate Telugu festivals and traditions.</li>
                    <li>To create a support system for Telugu families, students, and professionals in Bergen.</li>
                    <li>To promote the Telugu language and its rich literature through educational activities.</li>
                    <li>To build strong connections with other cultural organizations in Norway.</li>
                    <li>To support charitable initiatives in both Norway and India.</li>
                </ul>
            </section>
        </div>
    );
};

export default About;