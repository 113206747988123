// src/components/ImageSlider.tsx
import React from 'react';
import Slider from 'react-slick';
import './css/ImageSlider.css'; // Create a CSS file for styling

const ImageSlider: React.FC = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000, // Adjust the speed as needed
    };

    const images = [
        `${process.env.PUBLIC_URL}/bta_gallery_1.jpg`,
        `${process.env.PUBLIC_URL}/bta_gallery_2.jpg`,
        `${process.env.PUBLIC_URL}/bta_gallery_3.jpg`,
        `${process.env.PUBLIC_URL}/diwali_2024.jpg`,
        `${process.env.PUBLIC_URL}/ramadan_2023.jpg`,
    ];

    return (
        <div className="slider-container">
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index}>
                        <img src={image} alt={`Slide ${index + 1}`}/>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageSlider;
