// src/components/Home.tsx
import React from 'react';
import {Helmet} from 'react-helmet';
import ImageSlider from './ImageSlider';

const Home: React.FC = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Helmet>
                <title>Home - Bergen Telugu Association</title>
                <meta name="description"
                      content="Welcome to the Bergen Telugu Association. Join us in celebrating Telugu culture and traditions."/>
            </Helmet>
            <h1>Welcome to Bergen Telugu Association</h1>
            <p>
                Preserve, maintain and spread the tradition and culture of the people of Telugu origin.<br/>
                Promote Telugu culture, language, tradition and unity through assistance.<br/>
                Arrange cultural programmes, dance classes, cooking classes, language courses, sports activities and
                social events.<br/>
            </p>
            <ImageSlider/>

        </div>
    );
};

export default Home;
