import React from 'react';
import {Helmet} from 'react-helmet';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Events from './components/Events';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

const App: React.FC = () => {
    return (
        <Router>
            <Helmet>
                <title>Bergen Telugu Association</title>
                <meta name="description"
                      content="Bergen Telugu Association (BTA) unites and supports the Telugu-speaking community in Bergen, Norway."/>
                <meta name="keywords" content="Bergen, Telugu, Association, BTA, Norway, Culture, Community"/>
            </Helmet>
            <div>
                <Header/>
                <Navbar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/events" element={<Events/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                </Routes>
                <Footer/>
            </div>
        </Router>
    );
};

export default App;