import React from 'react';
import {Helmet} from 'react-helmet';
import './css/Contact.css';
import SocialMediaLinks from "./SocialMediaLinks";

// Array of board members with their names and picture URLs
const boardMembers = [
    {name: 'Chandra Sekhar Vuchuru', imageUrl: './placeholder_member.jpg', position: 'President'},
    {name: 'Irfan Ali Shaik', imageUrl: './placeholder_member.jpg', position: 'Vice President'},
    {name: 'Sharat Chandra Tumu', imageUrl: './placeholder_member.jpg', position: 'Treasurer'},
    {name: 'Durga Adiraju', imageUrl: './placeholder_member.jpg', position: 'Entertainment Head'},
    {name: 'Uma Sankari  Kanuri', imageUrl: './placeholder_member.jpg', position: 'Social Media Head'},
    // Add more members as needed
];

const Contact: React.FC = () => {
    return (
        <div className="contact-us-container">
            <Helmet>
                <title>Contact Us - Bergen Telugu Association</title>
                <meta name="description"
                      content="Get in touch with the Bergen Telugu Association. We are here to help and answer any questions you may have."/>
            </Helmet>
            <p>Have any questions or need assistance? Reach out to us at <b>bergentelugu@gmail.com</b></p>
            <a href="https://forms.gle/ydXZiunWmH5e9icT8" target="_blank" rel="noreferrer">Registration for 2025</a>
            {/* Board Members Section */}
            <section className="board-section">
                <h2>Our Board Members</h2>
                <div className="board-grid">
                    {boardMembers.map((member) => (
                        <div key={member.name} className="board-card">
                            <img src={member.imageUrl} alt={member.name} className="board-image"/>
                            <h3>{member.name}</h3>
                            <p>{member.position}</p>
                        </div>
                    ))}
                </div>
            </section>
            <p>Check out more about us on</p>
            <SocialMediaLinks/>


        </div>
    );
};

export default Contact;