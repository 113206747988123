import React from 'react';
import {Helmet} from 'react-helmet';
import './css/Events.css'; // Style for the events page

// Festival data (name and image)
const festivals = [
    {
        name: 'Sankranti',
        imageUrl: './sankranti.jpg',
        date: '19.01.2025',
        description: 'Makar Sankranti is famous for its sesame sweets and kite flying.'
    },
    {
        name: 'Sports Day',
        imageUrl: './sports_day.jpg',
        date: 'TBD',
        description: 'Sports Day is celebrated with various games and competitions for kids.'
    },
    {
        name: 'Ugadi',
        imageUrl: './ugadi.jpg',
        date: '29.03.2025',
        description: 'Ugadi is the Telugu New Year and is celebrated with traditional food and decorations.'
    },
    {
        name: 'Ramadan',
        imageUrl: './ramadan.jpg',
        date: 'TBD',
        description: 'Ramadan is the ninth month of the Islamic calendar and is observed by fasting and prayer.'
    },
    {
        name: 'Summer Gathering',
        imageUrl: './summer_gathering.jpg',
        date: 'TBD',
        description: 'Summer Gathering is a fun event with games, food, and music.'
    },
    {
        name: 'Vinayaka Chavithi',
        imageUrl: './vinayaka_chavithi.jpg',
        date: '23.08.2025',
        description: 'Vinayaka Chavithi is the birthday of Lord Ganesha and is celebrated with modak sweets.'
    },
    {
        name: 'Dasara',
        imageUrl: './dasara.jpg',
        date: '28.09.2025',
        description: 'Dasara is a 10-day festival that celebrates the victory of good over evil.'
    },
    {
        name: 'Deepavali',
        imageUrl: './deepavali.jpg',
        date: '18.10.2025',
        description: 'Deepavali is the festival of lights and is celebrated with fireworks and sweets.'
    },
    {
        name: 'Christmas',
        imageUrl: './christmas.jpg',
        date: 'TBD',
        description: 'Christmas is celebrated with carols, gifts, and feasting.'
    },
    {
        name: 'New year',
        imageUrl: './new_year.jpg',
        date: '31.12.2024',
        description: 'New Year is celebrated with parties, fireworks, and resolutions.'
    },

];

const Events: React.FC = () => {
    return (
        <div className="events-container">
            <Helmet>
                <title>Upcoming Events - Bergen Telugu Association</title>
                <meta name="description" content="Events being celebrated by Bergen Telugu Association."/>
            </Helmet>
            <h1>Upcoming BTA Events in 2025</h1>
            <div className="festivals-grid">
                {festivals.map((festival) => (
                    <div key={festival.name} className="festival-card">
                        <img src={festival.imageUrl} alt={festival.name} className="festival-image"/>
                        <h2>{festival.name}</h2>
                        <h4>{festival.date}</h4>
                        <p>{festival.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Events;
